import request from '@/util/request'

export const POSTAuth = (url:any, params:any = {}) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    for ( const key in params ) {
      formData.append(key, params[key]);
    }
    request.post(url, formData, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'channel': 'admin'
      }
    })
    .then((res:any) => {
      resolve(res)
    }).catch((error:any) => {
      reject(error)
    })
  })
};

export const DELETEAuth = (url:any, params:any = {}) => {
  return new Promise((resolve, reject) => {
    request.delete(url, {
      data: params
    })
    .then((res:any) => {
      resolve(res)
    }).catch((error:any) => {
      reject(error)
    })
  })
};

export const PUTAuth = (url:any, params:any = {}) => {
  return new Promise((resolve, reject) => {
    request.put(url, params)
    .then((res:any) => {
      resolve(res)
    }).catch((error:any) => {
      reject(error)
    })
  })
};

export const GETAuth = (url:any, params:any = {}) => {
  return new Promise((resolve, reject) => {
    request.get(url, params)
    .then((res:any) => {
      resolve(res)
    }).catch((error:any) => {
      reject(error)
    })
  })
};

export const GET = (url:any, params:any = {}) => {
  return new Promise((resolve, reject) => {
    request.get(url, params)
        .then((res:any) => {
          resolve(res)
        }).catch((error:any) => {
      reject(error)
    })
  })
};

export const POST = (url:any, params:any = {}) => {
  return new Promise((resolve, reject) => {
    request.post(url, params)
    .then((res:any) => {
      resolve(res)
    }).catch((error:any) => {
      reject(error)
    })
  })
};

