import axios from 'axios';
import store from "@/store";

const API_HOST = '/';

const instance = axios.create({
  baseURL: API_HOST,
  timeout: 100000,
  withCredentials: true,
});

// eslint-disable-next-line
// @ts-ignore
// axios的retry ts类型有问题
instance.interceptors.retry = 3;

instance.interceptors.request.use((config) => {
  const headers = {
    'content-type': 'application/json; charset=UTF-8',
    'channel': 'admin'
  };
  config.headers = { ...config.headers, ...headers };
  return config
});

instance.interceptors.response.use(
  (response:any) => {
    if (response.status === 200) {
      const { config, headers } = response;
      if ( !config.url.includes("/login") && headers['login-status'] === '9') {
        store.commit('setUser', null);
        location.replace('/login')
      } else {
        return response;
      }
    }
  },
  (err:any) => {
    const { config } = err;
    if (!config || !config.retry) return Promise.reject(err);
    config.retryCount = config.retryCount || 0;
    if (config.retryCount >= config.retry) {
      return Promise.reject(err);
    }
    config.retryCount += 1;
    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        resolve({});
      }, config.retryDelay || 1);
    });
    return backoff.then(() => instance(config));
  },
);

export default instance;
